import React, { createContext, useContext, useState } from 'react';
import { node } from 'prop-types';
// import mitt from 'mitt'

export const HeaderContext = createContext({});

export function useHeaderContext() {
  return  useContext(HeaderContext);
}

const initHeader= {
    page: 'HOME',
    title: '',
    description: '',
    button: null,
    justifyContent: 'center',
    textAlign: 'center',
    headerBackground: '',
}

function HeaderProvider({ children }) {

  const [headerContent, setHeaderContent] = useState(initHeader);
  const [onClickEvent, setEvent] = useState(null);

  function setHeader(payload) {
    setHeaderContent(prev => ({
      ...prev,
      ...payload
    }));
  }

  function onClick(event) {
    if (event) {
      event.preventDefault();
    }
    setEvent(event);
  }

  const headerStore = {
    headerContent,
    onClickEvent,
    dispatch: {
      setHeader,
      onClick
    }
  };

  return <HeaderContext.Provider value={headerStore}>{children}</HeaderContext.Provider>;

}

HeaderProvider.propTypes = {
  children: node.isRequired
};

export default HeaderProvider;
