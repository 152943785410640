import axios from 'axios';

export const getOpenCarrier = async (params) => {
    try {
      const { data } = await axios.get(`/data/open-jobs.json`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject({ status: 'error'});
    }
};

export const getOurWorks = async (params) => {
  try {
    const { data } = await axios.get(`/data/our-works.json`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ status: 'error'});
  }
};

export const getCustomers = async (params) => {
  try {
    const { data } = await axios.get(`/data/customers.json`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ status: 'error'});
  }
};

export const getCarrers = async (params) => {
  try {
    const { data } = await axios.get(`/data/carrers.json`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ status: 'error'});
  }
};

export const getContacts = async (params) => {
  try {
    const { data } = await axios.get(`/data/contact.json`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ status: 'error'});
  }
};