import React, { useEffect, useState, Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Skeleton from '@mui/material/Skeleton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Pages } from './page-menu';
import { useHeaderContext } from '../../contexts';
import Logox4 from '../../assets/icons/logox4.png';
import Logo from '../../assets/icons/logo.png';
import LogoBlackx4 from '../../assets/icons/logo-blackx4.png';
import LogoBlack from '../../assets/icons/logo-black.png';

const NavMenu = React.memo(({ handleeNavMenu, isHome, isNavFixed, content }) => {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path, e) => {
    setAnchorElNav(null);
    if (path) {
      handleeNavMenu(path, e);
    } 
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {isNavFixed ? 
            <img style={{ width: '180px', height: 'auto'}} src={sm ? LogoBlack : LogoBlackx4} alt="Logo" /> :
            content.page !== 'HOME' ?
            <img style={{ width: '180px', height: 'auto'}} src={sm ? LogoBlack : LogoBlackx4} alt="Logo" /> :
            <img style={{ width: '180px', height: 'auto'}} src={sm ? Logo : Logox4} alt="Logo" />
          }
        </Box> 
      </Box>

      <Box display="flex" alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
        {Pages.map((page, index) => (
          <MenuItem key={index} onClick={(e) => handleeNavMenu(page.path,e)}
            sx={{ 
              ':hover': { 
                background: isNavFixed || !isHome ? '#000000' : false,
                color: isNavFixed || !isHome ? '#ffffff' : false,
              }}}>
            <Typography textAlign="center" fontWeight="bold">{t(page?.title)}</Typography>
          </MenuItem>
        ))}
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', justifyContent: 'flex-end' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={(e) => handleCloseNavMenu(null,e)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {Pages.map((page, index) => (
                <MenuItem key={index} onClick={(e) => handleCloseNavMenu(page.path,e)}>
                  <Typography textAlign="center" color="text.secondary">{t(page?.title)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
    </Box>
  );
});

const Header = React.memo(() => {
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const { headerContent, dispatch } = useHeaderContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [isHome, setIsHome] = useState("/");
    const [isNavFixed, setNavScroll] = useState(false);
    const [content, setHeaderContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [offSet, setOffSet] = useState(120);

    const handleeNavMenu = (page,e) => {
      e.preventDefault();
      navigate(page);
    };
  
    useEffect(() => {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }, [content]);

    useEffect(() => {
      setHeaderContent(headerContent);
    }, [headerContent]);

    useEffect(() => {
      if (location.pathname === "/") {
        setTimeout(() => setIsHome(true), 300);
      } else {
        setIsHome(false);
      }
    }, [location]);
    
    useEffect(() => {
      setOffSet(xs ? 84 : 120)
    }, [xs]);
    
    useEffect(() => {
      if (isHome) {
        window.onscroll = function () { 
          if (window.scrollY >= offSet) {
            setNavScroll(true);
          }  else {
            setNavScroll(false);
          }
        };
      }
    }, [isHome, offSet]);

    return (
      <Fragment>
      <div className="app-container">
        <div className="backdrop"></div>
        <AppBar className="navbar" position="static" 
          sx={{ 
            height: { xs: '280px', md: isHome ? '100vh': '620px' },
            background:`url('${content.headerBackground}') no-repeat`
          }}>
            
          <Box className={`${!isHome ? 'nav-fixed-white': isNavFixed ? 'nav-fixed-white' : 'nav-fixed-tran'}`} 
            position="fixed" sx={{ height: { xs: '64px', md: '120px' }, width: '100%', zIndex: 9999 }}>
            <Container maxWidth="md" sx={{ height: '100%' }}>
              <Toolbar sx={{ height: '100%' }} disableGutters>
                <NavMenu handleeNavMenu={handleeNavMenu} isHome={isHome} isNavFixed={isNavFixed} content={content} />
              </Toolbar>
            </Container>
          </Box>
          <Box className="bg-tran" position="static" sx={{ mt: { xs: isHome? '50px': '64px', md: isHome?  '160px': '120px' }, height: { xs: '216px', md: '500px' }, zIndex: 999 }}>
            <Container maxWidth="md" sx={{ height: '100%' }}>
              <Toolbar sx={{ height: '100%',display: 'flex', alignItems: content ? content.justifyContent : 'center' , justifyContent: 'center', flexDirection: 'column' }} disableGutters>
                <Typography sx={{ width:{ xs: '90%', md: 500 } }} variant={ xs ? 'h5': sm ? 'h5 ': 'h3'} textAlign={ content ? content.textAlign: 'center' } component="div" fontWeight="bold" gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: loading ? "<Skeleton />" : t(content.title).replace(/\n/g, '<br />')
                  }} />
                <Typography  sx={{ width: { xs: '90%', md: 500 } }} variant={ xs ? 'body2': sm ? 'body2' : 'body1'}textAlign={ content ? content.textAlign: 'center' } component="div" gutterBottom>
                  {loading  ? <Skeleton /> : t(content.description)}
                </Typography>
                
                {loading  ? <Skeleton variant="rectangular" width={210} height={42}/> : 
                  content.button ? 
                  <Button onClick={dispatch.onClick} 
                    sx={{ 
                      mt: 2, 
                      color: 'text.secondary', 
                      background: '#FFFFFF', 
                      fontWeight: 'bold',
                      padding: '6px 25px', 
                      textTransform: 'none',
                      borderRadius: '8px',
                      ':hover': {
                        color: 'text.secondary', 
                        background: '#f5f5f5',
                      }
                    }}>
                  {t(content.button)}
                </Button>: false
                }
              </Toolbar>
            </Container>
          </Box>
        </AppBar>
      </div>
      </Fragment>
    );
  }
);

export default Header;