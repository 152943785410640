import React from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from './page-menu';
import Container from '@mui/material/Container';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logox4 from '../../assets/icons/logox4.png';
import Logo from '../../assets/icons/logo.png';

function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleeNavMenu = (page) => {
    navigate(page);
  };

  return (
    <footer style={{ background: '#121516' }}>
      <Container maxWidth="md">
        <Grid sx={{ paddingTop: sm ? '25px' : '50px', height: sm ? 'max-content' : '200px' }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant={ sm ? "h5" : "h4" } sx={{ fontWeight: 'bold', color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              Let's Talk
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              About your project.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              Contact Info.
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              admin@pandasoft.co.th
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              02-007-5257
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              Office.
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.primary', display: 'flex', alignItems: 'flex-start' }}>
              37/131 หมู่9 ถ. ติวานนท์ ต.บางพูด
              อ.ปากเกร็ด จ.นนทบุรี 11120
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2, border: '0.5px solid rgba(255, 255, 255, 0.2)' }} />
        <Grid sx={{ py: 2 }} container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" sx={{ color: 'text.primary', display: 'flex', alignItems: 'flex-start', justifyContent: sm ? 'center' : 'flex-start' }}>
              © 2023 Pandasoft & Development
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ display: sm ? 'none' : false }}>
            <img style={{ width: '180px', height: 'auto'}} src={sm ? Logo : Logox4} alt="Logo" />
          </Grid>
          <Grid item xs={12} md={5} sx={{ display: sm ? 'none' : false }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {Pages.map((page, index) => (
                <MenuItem key={index} onClick={() => handleeNavMenu(page.path)}>
                  <Typography color="text.primary" textAlign="center" variant="body2">{t(page?.title)}</Typography>
                </MenuItem>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer;
