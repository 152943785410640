import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useEmail } from '../services/mail.service';
import { useHeaderContext } from '../contexts';
import Button from '../components/Button';
import SEO from '../components/shared/SEO';
import GoogleMap from '../components/GoogleMap';
import { getContacts } from '../services/content.service';
import { t } from 'i18next';

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { sendEmail } = useEmail();
  const [open, setOpen] = useState({ type: 'success', state: false });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ type: 'success', state: false });
  };

  const onSubmit = (data) => {
    const subject = 'Mail contact from pandasoft.co.th';
    const body = `<html>
      <h2>Hello,</h2>
      <strong>You got a new message from ${data.name}</strong><br></br>
      <p style="margin: 0;">phone: ${data.phone},  email: ${data.email}</p>
      <p>${data.message}</p>
    </html>`;
    sendEmail({ subject, body })
      .then(() => {
        setOpen({ type: 'success', state: true });
        reset();
      })
      .catch(() => setOpen({ type: 'error', state: true }));
  };

  const test = () => {
    const recipient = 'recipient@example.com';
    const subject = 'Subject of the email';
    const body = 'Body of the email';
  
    // Construct the mailto URL
    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    // Open the user's default email client
    window.location.href = mailtoUrl;
  };

  return (
    <Fragment>
      <Snackbar open={open.state} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={open.type} sx={{ width: '100%' }}>
          {open.type === 'success' ? 'Send contact successfully!' : 'Send contact error!'}
        </Alert>
      </Snackbar>
      <button onClick={test}>TEST</button>
      <form style={{ paddingBottom: '4rem' }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Name*
          </Typography>
          <TextField placeholder="Name" fullWidth autoComplete="none" {...register('name', { required: true })} />
          {errors.name && (
            <Typography
              variant="caption"
              color="error.main"
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: 0
              }}>
              This Name is required
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Phone Number*
          </Typography>
          <TextField
            fullWidth
            placeholder="Phone Number"
            autoComplete="none"
            maxLength="10"
            {...register("phone", { 
              required: 'This Phone Number is required',
              pattern: {
                value: /^[0-9]{10}$/,
                message: 'Please enter a valid 10-digit mobile number',
              }})
            }
          />
          {errors.phone && (
            <Typography
              variant="caption"
              color="error.main"
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: 0
              }}>
              {errors.phone?.message}
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Email*
          </Typography>
          <TextField
            fullWidth
            placeholder="Email"
            autoComplete="none"
            {...register('email', {
              required: 'This Email is required',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email'
              }
            })}
          />
          {errors.email && (
            <Typography
              variant="caption"
              color="error.main"
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: 0
              }}>
              {errors.email?.message}
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Message
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            placeholder="Message"
            multiline
            rows={6}
            autoComplete="none"
            {...register('message')}
          />
        </Box>

        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            type="submit"
            sx={{
              mt: 2,
              fontWeight: 'bold',
              height: '38px',
              padding: '6px 40px',
              background: '#121516'
            }}>
            {t('contact-section2-button')}
          </Button>
        </Box>
      </form>
    </Fragment>
  );
};

function Contact() {
  const { t } = useTranslation();
  const { dispatch } = useHeaderContext();
  const [contact, setContact] = useState(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch.setHeader({
      page: 'CONTACT',
      title: 'contact-header-title',
      description: 'contact-header-desc',
      justifyContent: 'center',
      textAlign: 'center',
      button: null,
      headerBackground: '/images/background/bg-contact.jpg'
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchData() {
    const { data } = await getContacts();
    setContact(data);
  }

  return (
    <>
      <SEO title={`PandaSoft & development co., ltd`} description={contact?.address} />
      <Container sx={{ minHeight: 380 }} maxWidth="md">
        <Box sx={{ paddingTop: sm ? '3rem' : '6rem', paddingBottom: sm ? '3rem' : '6rem' }}>
          <Grid className="animated fadeIn" container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                variant={sm ? 'h6' : 'h5'}
                textAlign="left"
                component="div"
                fontWeight="bold"
                gutterBottom>
                {t('contact-section1-title')}
              </Typography>
              <Typography
                sx={{ mt: 0, color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                {contact?.email}
              </Typography>
              <Typography
                sx={{ mt: 0, color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                {contact?.phone}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant={sm ? 'h6' : 'h5'}
                textAlign="left"
                component="div"
                fontWeight="bold"
                gutterBottom>
                {t('contact-section1-title2')}
              </Typography>
              <Typography
                sx={{ mt: 0, color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                {contact?.address}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid className="animated fadeIn" item xs={12} sx={{ padding: '0px !important' }}>
          <GoogleMap focus={contact?.googleMap} />
        </Grid>
        <Box className="animated fadeIn" sx={{ marginTop: '5rem' }}>
          <Typography
            sx={{ mb: 4 }}
            variant={sm ? 'h5' : 'h4'}
            textAlign="center"
            component="div"
            fontWeight="bold"
            gutterBottom>
            {t('contact-section2-title')}
          </Typography>
          <ContactForm />
        </Box>
      </Container>
    </>
  );
}

export default Contact;
