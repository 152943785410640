export const getDesignTokens = () => ({
    typography: {
      fontFamily: "'Sukhumvit Set', sans-serif;"
    },
    palette: {
        text: {
            primary: '#FFFFFF',
            secondary: '#121516',
            disabled: ''
        },
        secondary: {
            main: '#F8F8F8'
        }
    } 
});
  