export const Pages = [
    {
      title: "pagetitle1",
      path: "/",
    },
    {
      title: "pagetitle2",
      path: "/work",
    },
    {
      title: "pagetitle3",
      path: "/careers",
    },
    {
      title: "pagetitle4",
      path: "/contact",
    },
];