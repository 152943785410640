import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@mui/material/Card';

import SEO from '../components/shared/SEO';
import { getCarrers } from '../services/content.service'
import { useHeaderContext } from '../contexts';
import { Grid } from '@mui/material';

const AvailableJob = React.memo(({ data, navigate, t, sm }) => {
  return <>
    <Box sx={{ mb: 5, p: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#FFFFFF', borderRadius: '8px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' ,justifyContent: 'center', alignItems: 'flex-start' }}>
        <Typography variant={ sm ? 'h5' : 'h6' } fontWeight="700" textAlign="left" component={ sm ? 'h5' : 'h6' } gutterBottom>
          {t(data.title)}
        </Typography>
        <Typography color="text.secondary" variant="body2" fontWeight="normal" textAlign="left" component="p" gutterBottom>
          {t(data.type)}
        </Typography>
      </Box>
      <Box>
        <IconButton aria-label="delete" onClick={() => navigate(`/careers/${data.id}`)}>
          <ArrowForwardIcon color="#1F2263"/>
        </IconButton>
      </Box>
    </Box>
  </>
});

function Career() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { dispatch } = useHeaderContext();
  const [availableJobs, setAvailableJobs] = useState([]);
  const welfare = [
    { name: 'Work from home', icon: '/images/icons/work-from-home.png' },
    { name: 'Macbook or Laptop', icon: '/images/icons/icon-laptop.png' },
    { name: 'Annual bonus', icon: '/images/icons/annual-bonus.png' },
    { name: 'Vacation', icon: '/images/icons/vacation.png' },
    { name: 'Company Party', icon: '/images/icons/company-party.png' },
    { name: 'Group Insurance', icon: '/images/icons/group-insurance.png' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch.setHeader({
      page: 'CAREER',
      title: 'careers-header-title',
      description: 'careers-header-desc',
      justifyContent: 'center',
      textAlign: 'center',
      button: null,
      headerBackground: '/images/background/bg-career.jpg'
    });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  async function fetchData() {
    const { data } = await getCarrers();
    if (data) {
      setAvailableJobs(data.fulltime);
    }
  }

  return (
    <Fragment>
      <SEO title="PandaSoft & development co., ltd" description="สมัครงานกับ Pandasoft &  Development, รับสมัครนักศึกษาฝึกงาน"/>
      <Container maxWidth="md">
        <Box sx={{ py: sm ? 3 : 5, display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ mt: sm ? 3 : 5, mb: 0, width: '80%' }} variant="body2" textAlign="center" component="div" gutterBottom>
            {t('carrers-section1-desc')}
          </Typography>
        </Box>
        <Box sx={{ mt: sm ? 3 : false}}>
          <Grid container spacing={2}>
            {welfare.map((item, index) => (
              <Grid key={index} item xs={6} md={4} sx={{ p: sm ? 4 : 5, paddingTop: sm ? '0 !important' : false }}>
                <Card variant="outlined" 
                  sx={{ 
                    p: sm ? 1 : 3, 
                    mb: sm ? 0 : 3, 
                    color: '#121516', 
                    border: '1px solid #EFEEF1',
                    display: 'flex', 
                    borderRadius: '16px',
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: sm ? '100%' : '180px', 
                    height: sm ? '165px' : '180px' }}>
                  <Box sx={{ mb: sm ? 1.5 : 3, width: '120px', height: '120px' }}>
                    <img style={{ width: '100%', height: '100%'}} src={`${process.env.PUBLIC_URL + item.icon}`} alt={item.name} />
                  </Box>
                  <Typography variant="body2" textAlign="center" component="h6" fontWeight="bold" gutterBottom>
                    {t(item.name)}
                  </Typography>
                </Card>
              </Grid>)) 
            }
          </Grid>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: '#EFEEF1', py: 6 }}>
        <Container sx={{ mt: 5 }} maxWidth="md">
          <Typography sx={{ width: '100%' }} variant="h5" fontWeight="700" textAlign="center" component="h5" gutterBottom>
            {t('carrers-section2-title')}
          </Typography>
          <Box sx={{ mt: 5 }}>
            { availableJobs.map((item, index) => <AvailableJob key={index} data={item} sm={sm} navigate={navigate} t={t} />) }
          </Box>
        </Container> 
      </Box>
    </Fragment>
  )
}

export default Career;
