import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHeaderContext } from '../contexts';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { getOurWorks } from '../services/content.service'
import { 
  Container,
  Box,
  Grid,
  MenuItem,
  Typography,
  Pagination,
  Stack,
  Skeleton,
} from "@mui/material";
import CustomButton from "../components/Button";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import SEO from '../components/shared/SEO'

const FilterMobile = ({ filterMenu, handleFillter, t }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  return (
    <Fragment>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button"  sx={{ boxShadow: 'none' }}>
          <Button 
          sx={{ 
            fontWeight: 'bold',
            width: '220px', 
            height: '42px',
            borderRadius: 40, 
            background: '#121516', 
            ':hover': {
              background: '#121516'
            }
          }}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
            {t(filterMenu[selectedIndex].label)}
          </Button>
        </ButtonGroup>

        <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{zIndex: 2147483647 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 99999999999,
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ background: '#FFFFFF', color: 'text.secondary' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {filterMenu.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === selectedIndex}
                      onClick={(event) => {handleMenuItemClick(event, index); handleFillter(event, option)}}
                    >
                      {t(option.label)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
} 

const Section1 = React.memo(({ loading, navigate, t, sm }) => {
  return <>
    <Box sx={{ backgroundColor: '#EFEEF1' }}>
      <Container maxWidth="md">
        <Grid sx={{ alignItems: 'center', minHeight: '600px', py: 3 }} container spacing={2}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Box sx={{ py: sm ? 2 : 5, width: '100%' }}>
              <Typography sx={{ mt: sm ? 0 : 2, mb: 0, color: '#000000' }} variant="h4" textAlign="left" component="div" fontWeight="bold" gutterBottom>
                {loading ? <Skeleton sx={{ bgcolor: 'grey.300', py: 1 }} /> : t('works-content-title') }
              </Typography>
              <Typography sx={{ mt: sm ? 0 : 2, mb: 0, color: '#000000' }} variant="body2" textAlign="left" component="div" gutterBottom>
                {loading ? <Skeleton sx={{ bgcolor: 'grey.300', py: 1 }} /> : t('works-content-desc') }
              </Typography>
              <CustomButton className="btn" sx={{ mt: 5, fontWeight: 'bold', textTransform: 'none' }} onClick={() => navigate('/contact')}>{t('works-content-button')}</CustomButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Box sx={{ width: '100%', p: sm ? 0 : 5, display: 'flex', justifyContent: sm ? 'center' : 'flex-end' }}>
              <img width="350px" src={`${process.env.PUBLIC_URL + '/images/work.png'}`} alt='work' />
          </Box>
          </Grid>
        </Grid>
      </Container>           
    </Box>
  </>
})

function Work() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { dispatch } = useHeaderContext();

  const initFilterMenu = [
    { type: 'ALL', label: 'ourWork-filter-button1', active: true }, 
    { type: 'WEB_APP', label: 'ourWork-filter-button2', active: false },
    { type: 'WEB', label: 'ourWork-filter-button3', active: false  },
    { type: 'MOBILE_APP', label: 'ourWork-filter-button4', active: false  }
  ];

  const [filterMenu, setFilterMenu] = useState(initFilterMenu);
  const [lang, setLang] = useState('en');
  const [filter, setFilter] = useState('ALL');
  const [works, setWorks] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(1);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    dispatch.setHeader({
      page: 'WORK',
      title: 'works-header-title',
      description: 'works-header-desc',
      justifyContent: 'center',
      textAlign: 'center',
      button: null,
      headerBackground: '/images/background/bg-project.png'
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLang(i18n.language)
  }, [i18n.language]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getOurWorks();
      if (data) {
        const perChunk = 6;
        const filters =  [...data].filter(item => filter === 'ALL' ? true : [...item.type].includes(filter));    
        const newArray = filters.reduce((resultArray, item, index) => { 
          const chunkIndex = Math.floor(index/perChunk);
          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        }, []);
        setTotalPage(newArray.length);
        setWorks(newArray);
        setTimeout(() => setLoading(false), 300);
      }
    }
    fetchData();
  }, [filter]);
  

  const handleFillter = (e, menu) => {
    console.log(e, menu);
    e.preventDefault();
    const { type } = menu;
    const clone = [...filterMenu].map(val => ({...val, active: false}));
    const index = clone.findIndex(val => (val.type === type));
    if (index !== -1) {
      clone[index].active = true;
      setFilterMenu(clone);
      setFilter(type);
    }
  }

  const handlePagination = (event, page) => {
    event.preventDefault();
    setPage(page);
  }

  return (
    <Fragment>
      <SEO title={`PandaSoft & development co., ltd. ${t('works-header-title')}`} description={t('works-header-desc')} />
      <Section1 loading={loading} sm={sm} navigate={navigate} t={t} />
      <Box sx={{ paddingTop: sm ? '2rem' : '5rem' }}>
        <Container maxWidth="md">
          <Box sx={{ mt: 4, display:'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            { sm ? <FilterMobile filterMenu={filterMenu} handleFillter={handleFillter} t={t} /> : (
              filterMenu.map((menu, index) => (
                <MenuItem key={index} onClick={(e) => handleFillter(e, menu)} 
                  sx={{ 
                    mr: 1.5, 
                    height: 42, 
                    minWidth: 140, 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    borderRadius: 40, 
                    background: menu.active ? '#121516' : '#FFFFFF', 
                    border: '1px solid #121516',
                    ':hover': {
                      background: menu.active ? '#121516' : '#FFFFFF', 
                    }
                  }}>
                  <Typography color={menu.active ? '#FFFFFF': '#000000'} textAlign="center" fontWeight="600">{t(menu?.label)}</Typography>
                </MenuItem>
              ))
            )}
          </Box>
          <Typography sx={{ mt: 5, mb: 0, color: '#000000', width: sm ? '100%' : '70%' }} variant={ sm ? "body1" : "h5"} textAlign="left" component={ sm ? "div" : "h5"} fontWeight="700" gutterBottom>
            {loading ? <Skeleton sx={{ bgcolor: 'grey.300', py: 1 }} /> : t('works-content-menu-title') }
          </Typography>
          <Box sx={{ marginTop: '4rem' }}>
            <Grid container spacing={0}>          
              {works[page - 1]?.map((item, index) => (
                <Grid key={index} sx={{ p: sm ? 2 : 4 }} item xs={12} md={6}>
                  <Card sx={{ width: '100%', borderRadius: '16px', boxShadow: '0px 0px 15px 0px #0000000D' }}>
                    <CardMedia
                      component="img"
                      height="220"
                      image={`${process.env.PUBLIC_URL + item.image}`}
                      alt={item.image}
                    />
                    <CardContent sx={{ maxHeight: 140, minHeight: 140 }}>
                      <Typography sx={{ mt: 2, mb: 0 }} variant="h6" color="text.secondary" textAlign="left" component="div" fontWeight="bold" gutterBottom>
                        {loading ? <Skeleton sx={{ bgcolor: 'grey.300', py: 1 }} /> : lang === 'en' ? item.en.title: item.th.title}
                      </Typography>
                      <Typography sx={{ my: {xs : 1.5 } }} variant="body2" color="text.secondary" textAlign="left" component="div" gutterBottom>
                        {loading ? <Skeleton sx={{ bgcolor: 'grey.300', py: 1 }} /> : lang === 'en' ? item.en.description: item.th.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ mt: 6, pb: 5, display: 'flex', justifyContent: 'center' }}>
            <Stack spacing="1">
              {works.length > 0 ? <Pagination onChange={handlePagination} count={totalPage} variant="outlined" shape="rounded" /> : false}
            </Stack>
          </Box>
        </Container>           
      </Box>
    </Fragment>
  )
}

export default Work;
