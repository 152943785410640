import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const MuiButton = React.memo(({ id, name, onChange, onClick, disabled, endIcon, children, sx, type = 'submit'}) => {
  return (
    <Button
      id={id}
      type={type}
      name={name}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      onChange={onChange}
      sx={{
        color: '#EFEEF1',
        backgroundColor: '#121516',
        borderRadius: '8px',
        height: '48px',
        padding: '0 40px',
        ':hover': {
            backgroundColor: '#050708',
        },
        ...sx
      }}>
      {children}
    </Button>
  );
});

MuiButton.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  disabled: PropTypes.bool,
  sx: PropTypes.object
};

export default MuiButton;
