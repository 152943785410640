import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    lng: "en", // Default language
    fallbackLng: "en", // Fallback language
    debug: false,
    ns: "trans",
    defaultNS: "trans",
    backend: {
      loadPath: (lng, ns) => {
        // Construct the path based on the selected language and namespace
        return `/locales/${lng}/${ns}.json`;
      }
    },
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;