import React from 'react';
import { Helmet } from 'react-helmet-async'

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet>
      {/* //TODO add favicon */}
      {/* <link rel="shortcut icon" type="image/png" href="../images/person.svg"/> */}
      <title>{ title }</title>
      <meta name="description" content={ description } />
      <meta name="theme-color" content="#ffffff"></meta>
      <meta name="keywords" content="Careers, Innovation Company, IT-consulting, Project Management, Web development, Software Enterprise Solution, Mobile and Tablet Application"/>
    </Helmet>
  )
}

export default SEO;
