import React, { useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { useHeaderContext } from '../contexts';

function NotFound() {
  const navigate = useNavigate();
  const { dispatch, onClickEvent } = useHeaderContext();

  useEffect(() => {
    dispatch.setHeader({
      title: '404 | Page not found',
      description: 'Please check the URL in the address bar and try again.' ,
      justifyContent: 'flex-start',
      textAlign: 'left',
      button: 'Go back home',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onClickEvent) {
      dispatch.onClick(null);
      navigate('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickEvent]);
  
  return <Fragment></Fragment>;
}

export default NotFound;
