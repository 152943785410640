import React from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getDesignTokens } from './utils/material-theme.config';

import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import AppRouting from './app.routing';

function App() {
  const theme = createTheme(getDesignTokens());
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <AppRouting />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
