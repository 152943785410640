import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import PinDropIcon from '@mui/icons-material/PinDrop';
import { GoogleMap as Map, LoadScript, Marker } from '@react-google-maps/api';

// const Marker = () => <PinDropIcon color='background: rgba(236, 129, 75, 1)' />;

function GoogleMap({ focus, zoom = 11 }) {
  const [center, setCenter] = useState({ lat: 59.95,lng: 30.33 });
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const containerStyle = {
    width: '100%',
    height:  sm ? '280px': '500px',
  };

  useEffect(() => {
    if (focus) {
      setCenter(center => ({ ...center, ...focus }));
    } 
  }, [focus]);

  return <div style={{ height: sm ? '280px': '500px', width: '100%' }}>
  <LoadScript googleMapsApiKey={ process.env.MAP_API_KEY || 'AIzaSyCvXR4JUj9GSBaLaYJsus4L4_FPyHA2k6A' }>
    <Map
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}>
      <Marker position={center} />
    </Map>
  </LoadScript>
</div>
}

export default GoogleMap;
