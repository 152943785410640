import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomButton from "../components/Button";

import { useEmail } from '../services/mail.service';
import SEO from '../components/shared/SEO';
import { getCarrers } from '../services/content.service';
import { useHeaderContext } from '../contexts';

const JobsInfoElements = ({ lists = [], title = '' }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box sx={{ marginBottom: '30px' }}>
        <Typography variant={sm ? 'h5' : 'h4'} textAlign="left" component="div" fontWeight="bold" gutterBottom>
          {t(title)}
        </Typography>
        <Box sx={{ pl: { xs: 2, md: 6 } }}>
          {lists.map((list, index) => (
            <Typography key={index} sx={{ display: 'list-item' }} variant="body2" textAlign="left" component="p" gutterBottom>
              {list}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  )
}

const CareerForm = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const { sendEmail } = useEmail();
  const [open, setOpen] = useState({ type: 'success', state: false });
  const [attechfile, setAttechfile] = useState(undefined);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ type: 'success', state: false });
  };

  const onSubmit = data => {
    const subject = 'Mail apply jobs from pandasoft.co.th';
    const body =
      `<html>
      <h2>Hello,</h2>
      <strong>You got a new message from ${data.name}</strong><br></br>
      <p style="margin: 0;">phone: ${data.phone},  email: ${data.email}</p>
      <p>Job link: ${window.location.href}</p>
      <p>${data.message}</p>
    </html>`
    sendEmail({ subject, body, attechfile })
      .then(() => {
        setOpen({ type: 'success', state: true });
        reset();
      })
      .catch(() => setOpen({ type: 'error', state: true }));
  };

  const elRefAttechFile = (e) => {
    e.preventDefault();
    ref.current.click();
  }

  const handleAttechfile = async (e) => {
    if (e?.target?.files?.length > 0) {
      setAttechfile([
        {
          name: e.target.files[0].name,
          data: await toBase64(e.target.files[0])
        }
      ]);
      setValue('attechfile', e.target.files[0].name);
    }
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  return (
    <Fragment>
      <Snackbar open={open.state} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={open.type} sx={{ width: '100%' }}>
          {open.type === 'success' ? 'Send contact successfully!' : 'Send contact error!'}
        </Alert>
      </Snackbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant={sm ? 'h5' : 'h4'} textAlign="left" component="div" fontWeight="bold" gutterBottom>
          {t('carrerDetail-section3-title')}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Name*
          </Typography>
          <TextField
            fullWidth
            placeholder="Name"
            autoComplete="none"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <Typography
              variant="caption"
              color="error.main"
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: 0
              }}
            >
              This Name is required
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ width: '48%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
              Phone Number*
            </Typography>
            <TextField
              placeholder="Phone Number"
              autoComplete="none"
              maxLength="10"
              {...register("phone", { 
                required: 'This phone number is required',
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: 'Please enter a valid 10-digit phone number',
                }})
              }
            />
            {errors.phone && (
              <Typography
                variant="caption"
                color="error.main"
                sx={{
                  position: 'absolute',
                  bottom: '-20px',
                  left: 0
                }}
              >
                {errors.phone?.message}
              </Typography>
            )}
          </Box>
          <Box sx={{ width: '48%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
              Email*
            </Typography>
            <TextField
              placeholder="Email"
              autoComplete="none"
              {...register("email", {
                required: 'This Email is required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                }
              })}
            />
            {errors.email && (
              <Typography
                variant="caption"
                color="error.main"
                sx={{
                  position: 'absolute',
                  bottom: '-20px',
                  left: 0
                }}
              >
                {errors.email?.message}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Message
          </Typography>
          <TextField fullWidth placeholder="Message" multiline rows={6} autoComplete="none" {...register("message")} />
        </Box>
      
        <Box sx={{ mb: 3, position: 'relative' }}>
          <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
            Attech file Resume / CV* .pdf
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <input hidden={true} ref={ref} type="file" accept="application/pdf" onChange={handleAttechfile} />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: sm ? 'calc(100% - 135px)' : 'calc(100% - 140px)' }} >
              <TextField placeholder="Attech file Resume / CV* .pdf" label={watch("attechfile") ? '' : ''} fullWidth autoComplete="none" onClick={elRefAttechFile} {...register("attechfile", { required: 'This Attechfile is required' })} />
              {errors.attechfile && <Typography variant="caption" color="error.main" >{errors.attechfile?.message}</Typography>}
            </Box>
            <Button type="submit" onClick={elRefAttechFile}
              sx={{ fontWeight: 'bold', width: 120, height: '48px', padding: '0 20px', background: 'rgba(224, 224, 224, 1)', borderRadius: '25px', color: '#000000', ':hover': { background: 'rgba(224, 224, 224, 0.7)' } }} >
              {t('attechFile')}
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CustomButton type="submit"
            sx={{ mt: 2, textTransform: 'none', fontWeight: 'bold', height: sm ? '40px' : '38px', padding: '0 20px', color: 'text.primary' }} >
            {t('carrerDetail-section3-button')}
          </CustomButton>
        </Box>
      </form>
    </Fragment>
  )
};

function CareerDetail() {
  const { id } = useParams();
  const { dispatch, onClickEvent } = useHeaderContext();
  const [jobDetail, setJobDetail] = useState(null);
  const [descriptions, setSescriptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (onClickEvent) {
      document.getElementById('form').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      return () => {
        dispatch.onClick(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickEvent]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getCarrers();
      if (data) {
        const newArray = [...data.fulltime, ...data.internship];
        const job = newArray.find(job => job.id === parseInt(id));
        setJobDetail(job);
        setSescriptions(job.descriptions)
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    dispatch.setHeader({
      page: 'CAREER',
      title: jobDetail?.title,
      description: jobDetail?.type,
      button: 'carrerDetail-section3-button',
      justifyContent: 'flex-start',
      textAlign: 'left',
      headerBackground: '/images/background/bg-career.jpg'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDetail]);



  return (
    <Fragment>
      <SEO title={`PandaSoft & development co., ltd ${jobDetail?.title}`} description={`สมัครงานกับ Pandasoft &  Development, รับสมัครนักศึกษาฝึกงาน ${jobDetail?.description}`} />
      <Container sx={{ py: 6, minHeight: 380 }} maxWidth="md">
        {descriptions.map((item, index) => (
          <JobsInfoElements key={index} title={item.title} lists={item?.list} />
        ))}
        <Divider sx={{ my: 4 }} />
        <Box id="form" className="animated fadeIn" sx={{ mb: 4 }} >
          <CareerForm />
        </Box>
      </Container>
    </Fragment>
  )
}

export default CareerDetail;
