import { useRoutes } from "react-router-dom";

import Home from './pages/Home';
import Work from './pages/Work';
import Career from './pages/Career';
import CareerDetail from './pages/CareerDetail';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

const AppRouting = () => {
    const routes = useRoutes([
      { paht: '', index: true, element: <Home /> },
      { path: 'work', element: <Work />},
      { path: 'careers', element: <Career />},
      { path: 'careers/:id', element: <CareerDetail />},
      { path: 'contact', element: <Contact />},
      { path: '*', element: <NotFound />}
    ]);
    return routes;
}

export default AppRouting;