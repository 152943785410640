import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {default as MultiCarousel} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function CustomRightArrow({ onClick }) {
  return (
    <button
      onClick={onClick}
      aria-label="Go to next slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right">
      <ArrowForwardIcon />
    </button>
  );
}

function CustomLeftArrow({ onClick }) {
  return (
    <button
      onClick={onClick}
      aria-label="Go to prev slide"
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left">
      <ArrowBackIcon />
    </button>
  );
}

const Carousel = React.memo(({ containerClass, children, show, hideArrow = false, showDots = true }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: show
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: show
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: show
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: show
    }
  };

  return (
    <MultiCarousel 
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      arrows={!hideArrow}
      showDots={showDots}
      infinite={true}
      responsive={responsive}
      containerClass={containerClass}>
      {children}
    </MultiCarousel>
  );
});

export default Carousel;