import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Link from "@mui/material/Link";
import Button from "../components/Button";
import Grid from '@mui/material/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@mui/material/Card';
import AOS from 'aos';
import "aos/dist/aos.css";
import { styled } from '@mui/material/styles';
import Carousel from "../components/Carousel";
import SEO from '../components/shared/SEO';
import { getOurWorks, getCustomers, getOpenCarrier } from '../services/content.service'
import { useHeaderContext } from '../contexts';

import WebDevelop from '../assets/icons/web-develop-black.svg';
import SoftwareSolution from '../assets/icons/software-solution-black.svg';
import Phone from '../assets/icons/phone-black.svg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none'
}));

const Section1 = ({ t, sm }) => {
  return <>
    <Container sx={{ py: 3, minHeight: '550px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant={ sm ? 'h5' : 'h4'} textAlign="center" component="div" fontWeight="bold" gutterBottom>
        {t('home-section1-title')}
      </Typography>
      <Box sx={{ mt: sm ? 1 : 4, display: 'flex', justifyContent: 'center', alignItems: { xs: 'center', sm: 'flex-start' }, flexDirection: { xs: 'column', sm: 'row' } }} data-aos={ sm ? false : "fade-up" }>
        <Card variant="outlined" sx={{ mx: { xs: 1, md: 2, lg: 3, xl: 4 }, my: 2, pt: 5, borderRadius: '16px' , color: '#121516', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '250px', minHeight: sm ? '200px' : '250px' }}>
          <Box sx={{ width: '138px', height: '138px' }}>
            <img src={WebDevelop} alt="WebDevelop" />
          </Box>
          <Typography marginTop="20px" variant={ sm ? "body2" : "h6"} textAlign="center" component="div" fontWeight="bold" gutterBottom>
            {t('home-section1-subtitle1')}
          </Typography>
        </Card>

        <Card variant="outlined" sx={{ mx: { xs: 1, md: 2, lg: 3, xl: 4 }, my: 2, pt: 5, borderRadius: '16px' , color: '#121516', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '250px', minHeight: sm ? '200px' : '250px' }}>
          <Box sx={{ width: '138px', height: '138px' }}>
            <img src={SoftwareSolution} alt="SoftwareSolution" />
          </Box>
          <Typography marginTop="20px" variant={ sm ? "body2" : "h6"} textAlign="center" component="div" fontWeight="bold" gutterBottom>
            {t('home-section1-subtitle2')}
          </Typography>
        </Card>

        <Card variant="outlined" sx={{ mx: { xs: 1, md: 2, lg: 3, xl: 4 }, my: 2, pt: 5, borderRadius: '16px' , color: '#121516', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '250px', minHeight: sm ? '200px' : '250px' }}>
          <Box sx={{ width: '138px', height: '138px' }}>
            <img src={Phone} alt="Phone" />
          </Box>
          <Typography marginTop="20px" variant={ sm ? "body2" : "h6"} textAlign="center" component="div" fontWeight="bold" gutterBottom>
            {t('home-section1-subtitle3')}
          </Typography>
        </Card>
      </Box>
    </Container>
  </>
}

const Section2 = ({ t, navigate, sm }) => {
  return <>
    <Container sx={{ py: 3, minHeight: '550px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Grid sx={{ alignItems: 'center' }} container spacing={2}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Item>
            <Typography variant={ sm ? "h5" : "h4" } textAlign="left" component="div" fontWeight="bold" gutterBottom>
              {t('home-section2-title')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('home-section2-desc')}
            </Typography>
            <Button className="btn" sx={{ mt: 2, fontWeight: 'bold', textTransform: 'none' }} endIcon={<ArrowForwardIcon />} onClick={() => navigate('/contact')}>{t('home-section2-button')}</Button>
          </Item>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Item sx={{ textAlign: "center", width: "100%"}}>
            <img style={{ width: sm ? '320px' : '380px', height: sm ? '320px' : '380px', borderRadius: '50%', objectFit: 'cover', objectPosition: 'center' }} src={`${process.env.PUBLIC_URL + '/images/2a8264398b993c7f042c58a23d459120.jpeg'}`} alt="work-plane" />
          </Item>
        </Grid>
      </Grid>
    </Container>
  </>
}

const Section2part1 = ({ t, navigate, sm }) => {
  return <>
    <Box className="bg-section" textAlign="center" sx={{ display: 'flex', mt: sm ? 4 : 0  }}>
      <Container maxWidth="md">
        <Grid sx={{ alignItems: 'center', minHeight: '280px'}} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography color="#FFFFFF" variant={ sm ? 'h5' : 'h4'} textAlign="left" component="div" fontWeight="bold" gutterBottom
            dangerouslySetInnerHTML={{
              __html: t('home-section2-1-title').replace(/\n/g, '<br />')
            }} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: { lg: 'right', xs: 'center'} }}>
            <Button className="btn" sx={{ mt: 2, fontWeight: 'bold', textTransform: 'none', backgroundColor: '#EFEEF1', color: '#121516', ':hover': { backgroundColor: '#e7e6eb' }, }} endIcon={<ArrowForwardIcon />} onClick={() => navigate('/contact')}>{t('home-section2-button')}</Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
}

const Section3 = ({ t, sm, lang, ourWorks }) => {
  return <>
    <Container sx={{ py: 3, minHeight: '550px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ py: 4, textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center' }} data-aos="fade-up">
        <Typography variant={ sm ? 'h5' : 'h4'} textAlign="center" component="div" fontWeight="bold" gutterBottom>
          {t('home-section3-title')}
        </Typography>
        <Container sx={{ mt: sm ? 0 : 1 }}>
          <Carousel show={ sm ? 1: 2 } hideArrow={true} >
            {ourWorks.map((ourWork, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '90%',py: 4, px: 2, minHeight: '320px'}}>
                  <img
                    width="100%"
                    style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', borderRadius: 8  }}
                    src={`${process.env.PUBLIC_URL + ourWork.image}`}
                    alt={ourWork.en.title}
                  />
                  <Typography sx={{ mt: 2, mb: 0 }} variant="h6" textAlign="left" component="div" fontWeight="bold" gutterBottom>
                    {lang === 'en' ? ourWork.en.title: ourWork.th.title }
                  </Typography>
                  <Typography variant="body2" textAlign="left" component="div" gutterBottom>
                    {lang === 'en' ? ourWork.en.description: ourWork.th.description }
                  </Typography>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Container>
      </Box>
    </Container>
  </>
}

const Section4 = ({ t, sm, customersChunk }) => {
  console.log('customersChunk', customersChunk);
  return <>
    <Box sx={{ py: 4, minHeight: '550px', textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: 'rgba(239, 238, 241, 0.4)' }} data-aos="fade-up">
      <Container maxWidth="md">
        <Typography variant={ sm ? 'h5' : 'h4'} textAlign="center" component="div" fontWeight="bold" width="100%" gutterBottom>
          {t('home-section4-title')}
        </Typography>
        <Container sx={{ mt: 4 }}>
          <Carousel show={1} hideArrow={true}>
            {customersChunk.map((array, index) => (
              <Box key={index} sx={{ pb: sm ? 1 : 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                {array?.map((customer, i) => (
                  <Link key={i} href={customer.link} target="_blank" rel="noreferrer" sx={{ width: '155px', height: '155px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                      width="auto"
                      height={ sm ? '90px' : '125px' }
                      style={{ filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))', borderRadius: 8  }}
                      src={`${process.env.PUBLIC_URL + customer.image}`}
                      alt={customer.name}
                    />
                  </Link>
                ))}
              </Box>
            ))}
          </Carousel>
        </Container>
      </Container>
    </Box>
  </>
}

const Section5 = ({ t, sm, md, jobs, lang, navigate }) => {
  return <>
    <Box sx={{ py: 3, minHeight: '550px', textAlign: "center", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} data-aos="fade-up">
      <Typography variant={ sm ? 'h5' : 'h4'} sx={{  mb: 0 }} textAlign="center" component="div" fontWeight="bold" gutterBottom>
        {t('home-section5-title')}
      </Typography>
      <Typography variant="body2" sx={{ width:{ xs:'90%', sm: '80%', md : '562px' }  }} gutterBottom>
        {t('home-section5-desc')}
      </Typography>
      <Container sx={{ mt: sm ? 0 : 4 }}>
        <Carousel show={ sm ? 1: md ? 2 : 3 } hideArrow={true} containerClass="carousel-container-careers" >
          {jobs.map((job, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box className="jobs-card" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' , width: '70%',py: 4, px: 2, minHeight: '160px' }}>
                <Typography sx={{ mb: 0 }} variant="h6" textAlign="left" component="div" fontWeight="bold" gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: (lang === 'en' ? job.en.title: job.th.title).replace(/\n/g, '<br />') 
                  }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography marginBottom="0" variant="body2" textAlign="left" component="div" fontWeight="bold" gutterBottom>
                    {lang === 'en' ? job.en.type: job.th.type }
                  </Typography>
                  <Link sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', fontWeight: 'bold', color: 'inherit' }} onClick={() => navigate(`/careers/${job.id}`)} >
                    <ArrowForwardIcon />
                  </Link>
                </Box>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Container>
    </Box>
  </>
}

function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [ourWorks, setOurWorks] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customersChunk, setCustomersChunk] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [lang, setLang] = useState('en');
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));


  const { dispatch, onClickEvent } = useHeaderContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  useEffect(() => {
    setLang(i18n.language)
  }, [i18n.language]);

  useEffect(() => {
    fetchData();
    dispatch.setHeader({
      page: 'HOME',
      title: 'home-header-title',
      description: 'home-header-desc',
      justifyContent: 'flex-start',
      textAlign: 'left',
      button: 'contact-header-title',
      headerBackground: '/images/background/bg-homev2.jpg'
    });
    AOS.init({
      duration: 2000
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onClickEvent) {
      console.log('contact');
      navigate('/contact');
      return () => {
        dispatch.onClick(null);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickEvent]);

  useEffect(() => {
    const perChunk = sm ? 3 : md ? 10 : 10;
    const clone = [...customers].reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    setCustomersChunk(clone);
  }, [sm, md, customers]);


  async function fetchData() {
    const { data: _works } = await getOurWorks();
    const { data: _customers } = await getCustomers();
    const { data: _jobs } = await getOpenCarrier();
    setOurWorks(_works);
    setCustomers(_customers);
    setJobs(_jobs);
  }

  return (
    <Fragment>
      <style>{`
        .jobs-card {
          background: #121516;
          border-radius: 16px;
          color: #FFFFFF;
        }
        .jobs-card:hover {
          background: #EFEEF1;
          color: #121516;
        }
      `}</style>
      <SEO title={'PandaSoft & development co., ltd'} description={t('home-header-desc')} />
      <Container maxWidth="md">
        <Section1 t={t} sm={sm} />
        <Section2 t={t} sm={sm} navigate={navigate} />
      </Container>
      <Section2part1 t={t} sm={sm} />
      <Container maxWidth="md">
        <Section3 t={t} sm={sm} lang={lang} ourWorks={ourWorks} />
      </Container>
      <Section4 t={t} sm={sm} customersChunk={customersChunk} />
      <Container maxWidth="md">
        <Section5 t={t} sm={sm} md={md} jobs={jobs} lang={lang} navigate={navigate} />
      </Container>
    </Fragment>
  );
}

export default Home;
