export const useEmail = () => {

  const sendEmail = async ({ subject, body, attechfile }) => {
    // eslint-disable-next-line no-undef
    Email.send({
      Host: "smtp.gmail.com",
      Username : "web.pandasoft@gmail.com",
      Password : "@Panda123",
      To : process.env.MAIL_RECEIVE || 'theeraprasert@pandasoft.co.th',
      Cc: process.env.MAIL_RECEIVE_CC || 'theeraprasert@pandasoft.co.th',
      From : "web.pandasoft@gmail.com",
      Subject : subject,
      Body : body,
      Attachments : attechfile ? attechfile : []
    })
      .then(message => {
        console.log('mail sent successfully', message);
        return Promise.resolve('mail sent successfully')
      }, err => {
        console.log('mail sent error.', err);
        return Promise.reject('mail sent error.')
      })
    }
    
  return { sendEmail };
}